import axios from "axios";
import React, { Component } from "react";
import { Bublic_Url, imageUpload_Url, Student_Url } from "./BaseUrl";
import Loading from "./Loading";

class AccountInfo extends Component {
  state = {
    picturepreview: "",
    loading: false,
  };


  render() {
    const { data } = this.props;
    const { loading, picturepreview } = this.state;
    // console.log(data)
    return (
      <>
        <Loading loading={loading} />
        <form action="">
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="form-group">
                <div className="course_img">
                  <label>
                    <div className="card_img">
                      {data.imagePath == null ? (
                        <div className="img_parent">
                          <img src={`${process.env.PUBLIC_URL}/images/vatar.webp`} alt="" />
                        </div>
                      ) : (
                        <div className="img_parent">
                          <img src={`${Bublic_Url}/${data.imagePath}`} alt="" />
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name=""
                  id=""
                  className="form-control"
                  disabled
                  value={data.fullName}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <input
                  type="email"
                  name=""
                  id=""
                  className="form-control"
                  disabled
                  value={data.email}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <input
                  type="number"
                  name=""
                  id=""
                  className="form-control"
                  disabled
                  value={data.phone}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name=""
                  id="adressS"
                  disabled
                  className="form-control"
                  placeholder="01098586783"
                  value={data.address}
                />
              </div>
            </div>
            {/* <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <input
                  type="password"
                  name=""
                  id=""
                  className="form-control"
                  disabled
                  value={data.password}
                />
              </div>
            </div> */}
          </div>
        </form>
      </>
    );
  }
}

export default AccountInfo;
