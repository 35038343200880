import React, { Component } from "react";
import Loading from "../component/Loading";

import axios from 'axios';
import { Student_Url } from "../component/BaseUrl";
import { toast } from "react-toastify";

export default class Login extends Component {

  state = {
    phone: "",
    password: "",
    loading: false,
    token: "",
  }


  handelChange = (e) =>{
    this.setState({
      [e.target.name] : e.target.value
    })
  }

  componentDidMount=()=>{
    document.title = 'تسجيل الدخول';

  }


  handelSubmit = (e) =>{
    e.preventDefault();
    this.setState({
      loading: true
    })
    const state = this.state;
    delete state.loading;
    delete state.token;
    axios.post(`${Student_Url}/Login`, state).then((res)=>{
      // console.log(res)
      if(res.status == 200){
        this.setState({
          loading: false,
          token: res.data.token
        })
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("studentId", res.data.student.id);
        toast.success(`تم تسجيل الدخول بنجاح`);
        setTimeout(()=>{
          this.props.history.replace("/profile");
          window.location.reload();
        }, 1000)
      }
    }).catch((error)=>{
      if(error.response){
        if (error.response.status == 404) {
          toast.error(error.response.data.title);
          toast.error(`برجاء التاكد من صحة البيانات`);
          this.setState({
            loading: false,
          });
        }
        // console.log(error.response)
      }
    })
  }

  render() {


    const {phone, password, loading} = this.state;
    return (
      <div>
        <Loading loading={loading} formpage={true} />
        <div className="login-page">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5">
              <div className="login-img" style={{backgroundImage: `url('./images/doooot.png  ')`}}>
                <h5 className="wow animate__animated animate__bounce">مرحبا بك</h5>
                <p className="wow animate__animated animate__shakeY animate__delay-.5s">
                  هذا النص هو يتم تغير في المكان الناسب هذا النص هو يتم تغير في
                  المكان الناسب هذا النص هو يتم تغير في المكان الناسب
                </p>
                <a
                  href="./index.html"
                >
                  العودة للرئيسية
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
              <div className="login-content">
                <div className="container">
                  <h5>برجاء تسجيل الدخول</h5>
                  <form action="" className="loginForm formStyle" onSubmit={this.handelSubmit}>
                    <div className="row">
                      <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="form-group wow animate__animated animate__flipInX animate__delay-1s">
                          <input
                            type="number"
                            name="phone"
                            id=""
                            value={phone}
                            className="form-control"
                            onChange={this.handelChange}
                            placeholder="رقم الهاتف"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="form-group wow animate__animated animate__flipInX animate__delay-2s">
                          <input
                            type="password"
                            name="password"
                            id=""
                            value={password}
                            className="form-control"
                            onChange={this.handelChange}
                            placeholder="كلمة المرور"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 text-center">
                        <button type="submit" className="btnSignin btn">
                          تسجيل الدخول
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="social">
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
