import axios from "axios";
import React, { Component } from "react";
import { StudenEnrollCourses_Url } from "./BaseUrl";
import CourseLinkCard from "./CourseLinkCard";

export class CourseLinks extends Component {
  state = {
    enrollcoursing: [],
    checking: "",
  };

  componentDidMount() {
    this.checkEnrolling();
  }

  checkEnrolling = async () => {
    // console.log(this.props);
    // const courseId = sessionStorage.getItem("coursseId");
    const studentId = sessionStorage.getItem("studentId");
    await axios
      .get(`${StudenEnrollCourses_Url}/StudentId?StudentId=${studentId}`)
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          this.setState({
            enrollcoursing: res.data,
          });
        }
      });

    const result = this.state.enrollcoursing.filter(
      (item) => item.id == this.props.courseId
    );
    // console.log(result);
    if (result.length == 0) {
      this.setState({
        checking: false,
      });
    } else {
      this.setState({
        checking: true,
      });
    }

    // console.log(this.state.checking);
  };

  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   const courseId = sessionStorage.getItem("coursseId");
  //   const studentId = sessionStorage.getItem("studentId");
  //   if (this.props.courseLinks !== prevProps.courseLinks) {
  //     // this.fetchData(this.props.userID);
  //     const result = this.state.enrollcoursing.filter(
  //       (item) => item.id == courseId
  //     );
  //     // console.log(result);
  //     if (result.length == 0) {
  //       this.setState({
  //         checking: false,
  //       });
  //     } else {
  //       this.setState({
  //         checking: true,
  //       });
  //     }
  //   }
  //   // console.log(this.state.checking);
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // console.log(this.props);
    const courseId = sessionStorage.getItem("coursseId");

    if (prevProps.courseId !== this.props.courseId) {
      // this.retreveDate(this.props.match.params.courseId);
      this.checkEnrolling();
      const result = this.state.enrollcoursing.filter(
        (item) => item.id == courseId
      );
      // console.log(result);
      if (result.length == 0) {
        this.setState({
          checking: false,
        });
      } else {
        this.setState({
          checking: true,
        });
      }
    }
  }

  render() {
    const { courseLinks } = this.props;
    const { checking } = this.state;
    // console.log(courseLinks);
    return (
      <>
        {checking ? (
          <div className="cours-content">
            {courseLinks.map((item) => (
              <CourseLinkCard item={item} key={item.id} />
            ))}
          </div>
        ) : (
          <h4 className="enroll_error">
            يجب عليك شراء الكورس اولا لتستفيد من المحتوى
          </h4>
        )}
      </>
    );
  }
}

export default CourseLinks;
