import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Api_Key } from "./APIKEY_Payment";
import {
  Authentication_Request_Url,
  Order_Registeration_Url,
  Payment_Key_Request_Url,
  Student_Url,
} from "./BaseUrl";
import Loading from "./Loading";

class CoursePayment extends Component {
  state = {
    coursePrice: "",
    courseName: "",
    api_key: "",
    auth_token: "",
    order_id: "",
    merchantorder_id: 1,
    loading: false,
    userdata: {},
    lastname: "",
    firstnname: "",
    enter: false,
  };

  componentDidMount = () => {
    const coursePrice = this.props.coursePrice;
    const courseName = this.props.thcourseName;
    // console.log(courseName, coursePrice)
    const api_key = Api_Key;
    // console.log(coursePrice);
    this.setState({
      coursePrice,
      api_key,
      courseName,
    });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const studentId = sessionStorage.getItem("studentId");
    if (studentId && this.state.enter === false) {
      this.getUserInfo(studentId);
    }

    const coursePrice = this.props.coursePrice;
    if (this.props.coursePrice !== prevProps.coursePrice) {
      // this.fetchData(this.props.userID);
      this.setState({
        coursePrice,
      });
    }
  }

  getUserInfo = (studentId) => {
    // console.log("hh")
    axios.get(`${Student_Url}/${studentId}`).then((res) => {
      if (res.status == 200) {
        var newlast = res.data.fullName.split(' ');
        var firstnname = newlast[0];
        var lstname;
        if(newlast.length > 1) {
          lstname = newlast.slice(1).join(' ')
        }else{
          lstname = newlast[0];
        }
        
       
        this.setState({
          userdata: res.data,
          lastname : lstname,
          firstnname : firstnname,
          enter: true,
        });
      }
      
    });
  };

  paymentRequest = () => {
    const studentId = sessionStorage.getItem("studentId");
    this.setState({
      loading: true,
    });
    if (studentId) {
      this.getAuthToken(studentId);
    } else {
      toast.error(`يجب عليك تسجيل الدخول اولا`);
      this.setState({
        loading: false
      })
    }
    // this.orderRequest();
    // this.paymentKey();
  };

  // paymob integration card payment

  // step1
  getAuthToken = async (studentId) => {
    const api_key = this.state.api_key;
    // console.log(api_key);
    await axios
      .post(`${Authentication_Request_Url}`, { api_key: api_key })
      .then((res) => {
        // console.log(res);
        if (res.status == 201) {
          this.setState({
            auth_token: res.data.token,
          });
          this.orderRequest(studentId);
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  // step2
  orderRequest = async (studentId) => {
    // var merchantorder_id = this.state.merchantorder_id;
    // merchantorder_id = merchantorder_id + 1;
    // console.log(studentId);
    // this.setState({
    //   merchantorder_id
    // })
    var order_object = {
      auth_token: this.state.auth_token,
      delivery_needed: "false",
      amount_cents: 100 * this.state.coursePrice,
      currency: "EGP",
      items: [
        {
          name: studentId,
          amount_cents: 100 * this.state.coursePrice,
          description: "Course",
          quantity: "1",
        },
      ],
    };
    // console.log(order_object);

    await axios
      .post(`${Order_Registeration_Url}`, order_object)
      .then((res) => {
        // console.log(res);
        if (res.status == 201) {
          this.setState({
            order_id: res.data.id,
          });
          this.paymentKey(studentId);
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  // step3
  paymentKey = async () => {
    var payment_object = {
      auth_token: this.state.auth_token,
      amount_cents: 100 * this.state.coursePrice,
      expiration: 3600,
      order_id: this.state.order_id,
      is_3d_secure: "true",
      billing_data: {
        apartment: "NA",
        email: this.state.userdata.email,
        floor: "NA",
        first_name: this.state.firstnname,
        street: "NA",
        building: "NA",
        phone_number: this.state.userdata.phone,
        shipping_method: "NA",
        postal_code: "NA",
        city: "NA",
        country: "NA",
        last_name: this.state.lastname,
        state: "NA",
      },
      currency: "EGP",
      integration_id: 3753009,
      lock_order_when_paid: "false",
    };

    console.log(payment_object);
    await axios
      .post(`${Payment_Key_Request_Url}`, payment_object)
      .then((res) => {
        // console.log(res);
        if (res.status == 201) {
          // this.setState({
          //   loading: false,
          // });
          window.location.href = `https://accept.paymob.com/api/acceptance/iframes/752957?payment_token=${res.data.token}`;
        
        }

        // navigate("/path/to/push");
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <button className="btn btn-block" onClick={this.paymentRequest}>
        <img src={`${process.env.PUBLIC_URL}/images/credit.webp`} alt="" />
          <span>شراء الكورس </span>
        </button>
      </>
    );
  }
}

export default CoursePayment;
