import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { intervalId_info, studentRequest_Url } from "./BaseUrl";
import Loading from "./Loading";
import WithdrowOrder from "./WithdrowOrder";

class AccountAffiliatemarketing extends Component {
  state = {
    loading: true,
    cashdata: [],
    show: false,
  };

  componentDidMount = () => {
    this.setState({
      loading: false,
    });
    const studentId = sessionStorage.getItem("studentId");
    this.retreveData(studentId);
  };

  retreveData = (studentId) => {
    axios.get(`${intervalId_info}/${studentId}`).then((res) => {
      if (res.status == 200) {
        this.setState({
          cashdata: res.data.res,
        });
      }
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleShow = (requestId) => {
    this.setState({
      show: true,
    });
  };

  render() {
    const { loading, cashdata, show } = this.state;
    const { data } = this.props;
    // console.log(data)
    return (
      <>
        <Loading loading={loading} />
        <div className="tottal_withdrow">
          <h5 className="total_price">
            المكافأه الاجماليه <span> {data.walletAmount} جنية </span>{" "}
          </h5>
          <div className="withdrow">
            <WithdrowOrder
              show={show}
              handleClose={this.handleClose}
              handleShow={this.handleShow}
            />
            <button className="btn" onClick={this.handleShow}>
              طلب سحب
              <img
                src={`${process.env.PUBLIC_URL}/images/credit.webp`}
                alt=""
              />
            </button>
          </div>
        </div>
        <div className="table-responsive affila_table_info_parent">
          <table className="table mt-5 affila_table_info">
            <thead>
              <tr>
                <th scope="col">الترتيب</th>
                <th scope="col">المدعو</th>
                <th scope="col">الكورس</th>
                <th scope="col">سعر الكورس</th>
                <th scope="col">العمولة</th>
              </tr>
            </thead>
            <tbody>
              {cashdata.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.studentName}</td>
                  <td>{item.courseName}</td>
                  <td>{item.amount * 10}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default AccountAffiliatemarketing;
