import axios from 'axios';
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { getDataBySerialNumber } from '../component/BaseUrl';
import Breadcrumb from '../component/Breadcrumb'
import Footer from '../component/Footer';
import Loading from '../component/Loading';

class Certificate extends Component {

  state = {
    loading: false, 
    sNumber: "",
  }

  componentDidMount=()=>{
    document.title = 'الشهادات';
  }

  handelChange=(e)=>{
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  submitForm =  (e) => {
    e.preventDefault();
    const state = { ...this.state };
    if (state.sNumber == "") {
      toast.error(`برجاء التأكد من رقم التحقق`);
    } else {
      this.setState({
        loading: true,
      });
       axios
        .get(`${getDataBySerialNumber}/${state.sNumber}/`)
        .then((res) => {
          // console.log(res.data)
          if (res.status == 200) {
            toast.success(`تم العثور على البيانات بنجاح`);
            this.props.history.replace('/certificate-details' , {
              state:{"data" : res.data}
            })
            this.setState({
              loading: false,
            });
          } else if (res.status == 204) {
            this.setState({
              loading: false,
            });
            toast.error(`لا توجد بيانات متطابقة`);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              loading: false,
            });
            // console.log(error.response);
            toast.error(`please try again later`)
          }
        });
    }
  };

  render() {
    const { data, loading, sNumber } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <Breadcrumb head="الشهادات" />
        <div className="login-page certificate_checker">
          <div className="login-content">
            <div className="container">
              <h5>برجاء كتابه رقم الشهاده</h5>
              <form action="" className="loginForm formStyle" onSubmit={this.submitForm}>
                <div className="row">
                  <div className="col-sm-12 col-lg-12 col-md-12">
                    <div className="form-group wow animate__animated animate__flipInX animate__delay-1s">
                      <input
                        type="text"
                        name="sNumber"
                        id=""
                        value={sNumber}
                        className="form-control"
                        onChange={this.handelChange}
                        placeholder="رقم التحقق"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-12 text-center">
                    <button type="submit" className="btnSignin btn">
                      التحقق
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Certificate