import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Sidebar extends Component {
  render() {
    const { closeSideMenue } = this.props;
    return (
      <div>
        <div>
          <div className="Mysidebar">
            <button className="closeSideMenue" onClick={closeSideMenue}>
              <i className="fas fa-times"></i>
            </button>
            <div className="logo">
              <a href="/">
                <img src="./images/logo.png" alt="" />
              </a>
            </div>
            <ul className="list-sidebar">
              <li className="navItem">
                <Link to="/" className="navLink" onClick={closeSideMenue}>
                  <i className="fi-rr-home"></i>
                  <span>الرئيسية</span>
                </Link>
              </li>
              <li className="navItem">
                <Link to="/courses" className="navLink" onClick={closeSideMenue}>
                  <i className="fi-rr-book"></i>
                  <span>التدريبات</span>
                </Link>
              </li>

              <li className="navItem">
                <Link to="/affiliatemarketing" className="navLink" onClick={closeSideMenue}>
                  <i className="fi-rr-users"></i>
                  <span>التسويق بالعمولة</span>
                </Link>
              </li>

              <li className="navItem">
                <Link to="/certificate" className="navLink" onClick={closeSideMenue}>
                  <i className="fi-rr-headset"></i>
                  <span>الشهادات</span>
                </Link>
              </li>
              <li className="navItem">
                <Link to="/aboutus" className="navLink" onClick={closeSideMenue}>
                  <i className="fi-rr-users"></i>
                  <span> من نحن</span>
                </Link>
              </li>
              <li className="navItem">
                <Link to="/contactus" className="navLink" onClick={closeSideMenue}>
                  <i className="fi-rr-headset"></i>
                  <span>تواصل معنا</span>
                </Link>
              </li>
            </ul>
            <div className="login">
                <Link to="/sign-up" className="navLink" onClick={closeSideMenue}>
                  <i className="fi-rr-user-add"></i>
                  <span>حساب جديد</span>
                </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
