import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Bublic_url } from "./BaseUrl";

export default class CourseCard extends Component {
  render() {

    const {item} = this.props;
    // console.log(item.courseImage)
    return (
      <Fragment>
        <div className="course-card">
          <div
            className="card-img"
         
          >
            <div className="img_parent">
              <img src={`${Bublic_url}/${item.courseImage}`}alt="" />
            </div>
          </div>
          <div className="card-body">
            <div className="card-instructor">
              <div
                className="card-img-instructio"
                style={{
                  backgroundImage: `url('./images/building-svg.svg')`,
                }}
              ></div>
              <span>{item.presenter?.fullName}</span>
            </div>
            <h5 className="card-title"> {item.name} </h5>
            <div className="card-info">
              <span className="card-rate">
                <i className="fa fa-star"></i>
                <span> {item.rate.toFixed(1)} </span>
              </span>
              <div className="card-duration">
                <i className="fi-rr-clock"></i>
                {item.time} ساعه
              </div>
            </div>

            <div className="card-price">
              {item.coursePrice} <span>ج.م</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
