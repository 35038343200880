import React, { Component } from "react";
// component
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";

import axios from "axios";
import CourseCard from "../component/CourseCard";
import { Course_Url } from "../component/BaseUrl";
import { Link } from "react-router-dom";
import Loading from "../component/Loading";
import GetUserToken from "../component/GetUserToken";

const token = GetUserToken();

export default class Courses extends Component {
  state = {
    data: [],
    loading: true,
    rate: 0,
  };

  componentDidMount() {
    document.title = 'التدريبات';

    this.retreveData();
    // console.log(token)
  }


  retreveData=()=>{
    axios.get(`${Course_Url}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      console.log(res.data);
      if (res.status == 200) {
        let data = res.data.reverse();
        this.setState({
          data,
          loading: false,
        });
      }
    });
  }

  render() {
    const { data, loading } = this.state;
    return (
      <div>
        <Loading loading={loading} />
        <Breadcrumb head="التدريبات" />
        <div className="courses_page">
          <div className="container">
          <div className="section-header">
              <div className="sec-head">
                <h5>التدريبات</h5>
                <p>جميع التدريبات</p>
              </div>
            </div>
            <div className="row">
              {data.map((item, index) => (
                <div className="col-sm-12 col-md-6 col-lg-4" key={item.id}>
                  <Link to={`/course-details/${item.id}`}>
                    <CourseCard item={item} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
