import axios from "axios";
import React, { Component } from "react";
import Loading from "../component/Loading";
import Footer from "../component/Footer";
import CryptoJS from 'crypto-js';


class PaymobRedirect extends Component {


  state = {
    transactionId: "",
    courseId: "",
    studentId: "",
    amount: 0,
    loading: true,
    payment_status: "",
    payment_status2: "",
    hmacarray: ["amount_cents", "created_at", "currency", "error_occured", "has_parent_transaction", "obj.id", "integration_id", "is_3d_secure", "is_auth", "is_capture", "is_refunded", "is_standalone_payment", "is_voided", "order.id", "owner", "pending", "source_data.pan", "source_data.sub_type", "source_data.type", "success"],
    hmacstring: "",
    newhmac:"",
  };

  componentDidMount = () => {
    document.title = "حالة الدفع";
    // console.log("333");
    const url_params = this.props.location.search;
    const payment_status = this.props.location.search.success;
    var obj = new URLSearchParams(url_params);
    const params = Object.fromEntries(new URLSearchParams(obj));
    const payment_status2 = params.success;
    console.log(params);

    for (let i = 0; i < this.state.hmacarray.length; i++) {
      const value = this.state.hmacarray[i];
      if (params.hasOwnProperty(value)) {
        this.state.hmacstring += value;        
      } 
    }
    let my_hmac = CryptoJS.HmacSHA512(this.state.hmacstring, "C0DEB9A4CE1D8F62EA1BF6BD4BF082F3");
    this.state.newhmac = my_hmac.toString(CryptoJS.enc.Hex);
    console.log(params.hmac, this.state.newhmac)

    var courseid = sessionStorage.getItem("coursseId");
    var studentId = sessionStorage.getItem("studentId");
    var order_id = params.id;
    // var hmac = params.hmac;
    var amount = params.amount_cents / 100;

    this.setState(
      {
        transactionId: order_id,
        courseId: courseid,
        studentId: studentId,
        amount: amount,
        loading: false,
        payment_status: payment_status,
        payment_status2: payment_status2,
      },
      () => {
        // console.log(this.state);
        const state = this.state;
        delete state.payment_status;
        delete state.payment_status2;
        delete state.loading;
        axios
          .post("https://americanboard-us.com/api/Payments", this.state)
          .then((res) => {
            // console.log(res);
            if (res.status == 200) {
              // console.log(res.data);
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    );
  };

  // http://egy-courses.com/paymenredirect

  render() {
    const { loading, payment_status, payment_status2 } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <div className="payment_redirect">
          <div className="container">
            <div className="wrapper">
              {payment_status2 === "true" ? (
                <div className="payment_redirect_card">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                  <h5>تم الدفع بنجاح</h5>
                  <p>
                    سوف يتم الموافقه على التدريب قريبا وسيتم التواصل معك من خلال
                    خدمة العملاء
                  </p>
                </div>
              ) : (
                <div className="payment_redirect_card field_payment">
                  <div className="circle-border"></div>
                  <div className="circle">
                    <div className="error"></div>
                  </div>

                  <h5>لم يتم الدفع </h5>
                  <p>برجاء المحاولة لاحقا</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer pagetype="footer" />
      </>
    );
  }
}

export default PaymobRedirect;
