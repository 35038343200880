import React, { Component } from 'react'
import { Dropdown } from "react-bootstrap";
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from './Loading';

class DropLoginButton extends Component {

  state = {
    loading: false,
  }

  logout = () =>{
    this.setState({
      loading: true,
    })
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("studentId");
    toast.success(`تم تسجيل الخروج بنجاح`)
    setTimeout(()=>{
      this.props.history.replace('/login');
      window.location.reload();
    }, 1000)
  }

  render() {
    const {loading} =  this.state;
    return (
      <>
      <Loading loading={loading} />
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            حسابي
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Link to="/profile"><i className="fa-solid fa-user"></i> الملف الشخصي </Link> 
            <button className='btn btn_logout' onClick={this.logout}> <i className="fa-solid fa-right-from-bracket"></i >تسجيل الخروج </button> 
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  }
}

export default withRouter(DropLoginButton);