import React from "react";

function CourseLinkCard({item}) {
  return (
    <>
      <div className="card">
        <div target="_blank" className="card-title">
          <span>{item.name}</span>
          <a className="btn" href={item.url} target="_blank">
            <span>زياره الكورس</span>
            <i className="fi-rr-link"></i>
          </a>
        </div>
      </div>
    </>
  );
}

export default CourseLinkCard;
