import React, { Component } from "react";
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";
import Loading from "../component/Loading";

class CertificateDetails extends Component {
  state = {
    loading: false,
    data: {},
    date: "",
  };

  componentDidMount() {
    document.title = "تفاصي الشهاده";
    const data = this.props.location.state.state.data;
    this.convertDate(data.date)
    this.setState({
      data,
    });
  }

  convertDate = (thedate) =>{
    const datadate = new Date(thedate);
    const date = datadate.toLocaleDateString("en-GB", {
      // day: "numeric",
      month: "long",
      year: "numeric",
    });
    this.setState({date: date});
  }

  render() {
    const { loading, data, date } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <Breadcrumb head="تفاصيل الشهاده" />

        <div className="certificate_details_page">
          <div className="container">
            <h5 className="head_page">تفاصيل الشهادة</h5>
            <div className="info_cards">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="info_card">
                    <div className="card_icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/building-svgrepo-com.svg`}
                        alt=""
                      />
                      <h4>الرقم التسلسلي</h4>
                    </div>
                    <h3 className="card_title">{data.sNumber}</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="info_card">
                    <div className="card_icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/person-in-tuxedo-svgrepo-com.svg`}
                        alt=""
                      />
                      <h4>الاسم</h4>
                    </div>
                    <h3 className="card_title">{data.name}</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="info_card">
                    <div className="card_icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/book-svgrepo-com (1).svg`}
                        alt=""
                      />
                      <h4>اسم الكورس</h4>
                    </div>
                    <h3 className="card_title">{data.traningName}</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="info_card">
                    <div className="card_icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/calendar-date-svgrepo-com.svg`}
                        alt=""
                      />
                      <h4>التاريخ</h4>
                    </div>
                    <h3 className="card_title">{date}</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="info_card">
                    <div className="card_icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/rank-svgrepo-com.svg`}
                        alt=""
                      />
                      <h4>الدرجه</h4>
                    </div>
                    <h3 className="card_title">{data.gpa}</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="info_card">
                    <div className="card_icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/clock-svgrepo-com.svg`}
                        alt=""
                      />
                      <h4>الوقت بالساعات</h4>
                    </div>
                    <h3 className="card_title">{data.durationByHour}</h3>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <div className="info_card">
                    <div className="card_icon">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/location-svgrepo-com.svg`}
                        alt=""
                      />
                      <h4>المكان</h4>
                    </div>
                    <h3 className="card_title">{data.location}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default CertificateDetails;
