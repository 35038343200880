import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Student_Url } from "../component/BaseUrl";
import Loading from "../component/Loading";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";

class SignUp extends Component {
  state = {
    phone: "",
    password: "",
    fullName: "",
    address: "",
    email: "",
    loading: false,
  };

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    document.title = 'تسجيل حساب';
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  submitForm = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    const state = this.state;
    const inviterId = sessionStorage.getItem("inviterId");
    if (inviterId === "undefined") {
      delete state.inviterId;
      // console.log("yes");
    } else {
      state.inviterId = inviterId;
    }
    delete state.loading;
    if (this.validator.allValid()) {
      axios
        .post(`${Student_Url}/Register`, state)
        .then((res) => {
          // console.log(state)
          if (res.status == 201) {
            toast.success(`شكرا يا ${state.fullName} على انضمامك لنا`);
            this.setState({
              loading: false,
              phone: "",
              password: "",
              fullName: "",
              address: "",
              email: "",
            });
            this.props.history.replace("/login");
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(error.response);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      toast.error("برجاء مراجعة الأخطاء");
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { loading, fullName, password, email, address, phone } = this.state;
    return (
      <div>
        <Loading loading={loading} formpage={true} />
        <div className="login-page rigister-page">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-5">
              <div
                className="login-img"
                style={{ backgroundImage: `url('./images/doooot.png  ')` }}
              >
                <h5 className="wow animate__animated animate__bounce">مرحبا بك</h5>
                <p className="wow animate__animated animate__shakeY animate__delay-.5s">
                  هذا النص هو يتم تغير في المكان الناسب هذا النص هو يتم تغير في
                  المكان الناسب هذا النص هو يتم تغير في المكان الناسب
                </p>
                <a href="./index.html">العودة للرئيسية</a>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
              <div className="login-content">
                <div className="container">
                  <h5>تسجيل حساب جديد</h5>
                  <form
                    action=""
                    className="loginForm formStyle"
                    onSubmit={this.submitForm}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 col-md-12">
                        <div className="form-group wow animate__animated animate__flipInX animate__delay-1s">
                          <input
                            type="text"
                            name="fullName"
                            id=""
                            value={fullName}
                            className="form-control"
                            onChange={this.handelChange}
                            placeholder="اسم المستخدم"
                          />
                          <span className="error_handle">
                            {this.validator.message(
                              "fullName",
                              this.state.fullName,
                              "required"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 col-md-12">
                        <div className="form-group wow animate__animated animate__flipInX animate__delay-1s">
                          <input
                            type="email"
                            name="email"
                            id=""
                            value={email}
                            className="form-control"
                            onChange={this.handelChange}
                            placeholder="البريد الالكتروني"
                          />
                          <span className="error_handle">
                            {this.validator.message(
                              "fullName",
                              this.state.fullName,
                              "required"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="form-group wow animate__animated animate__flipInX animate__delay-1s">
                          <input
                            type="text"
                            name="address"
                            id=""
                            value={address}
                            className="form-control"
                            onChange={this.handelChange}
                            placeholder="العنوان"
                          />
                          <span className="error_handle">
                            {this.validator.message(
                              "address",
                              this.state.address,
                              "required"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 col-md-12">
                        <div className="form-group wow animate__animated animate__flipInX animate__delay-1s">
                          <input
                            type="number"
                            name="phone"
                            id=""
                            value={phone}
                            className="form-control"
                            onChange={this.handelChange}
                            placeholder="رقم الهاتف"
                          />
                          <span className="error_handle">
                            {this.validator.message(
                              "phone",
                              this.state.phone,
                              "required"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-6 col-md-12">
                        <div className="form-group wow animate__animated animate__flipInX animate__delay-2s">
                          <input
                            type="password"
                            name="password"
                            id=""
                            value={password}
                            className="form-control"
                            onChange={this.handelChange}
                            placeholder="كلمة المرور"
                          />
                          <span className="error_handle">
                            {this.validator.message(
                              "password",
                              this.state.password,
                              "required"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 col-md-12">
                        <div className="have-account">
                          <Link to="/login">لدي حساب بالفعل</Link>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-12 text-center">
                        <button type="submit" className="btnSignin btn">
                          التسجيل
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="social">
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
