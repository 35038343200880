import React, { Component } from "react";
import Fancybox from "../component/FancyBox";

// react-router-dom
// import { Routes, Route, useParams, useNavigate } from "react-router-dom";

// component
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";

// react-bootstrap
import { Tab, Tabs } from "react-bootstrap";

import axios from "axios";
import {
  Bublic_url,
  CourseDetails_Url,
  Course_Url,
  enrollingCheacking_Url,
  StudenEnrollCourses_Url,
} from "../component/BaseUrl";
import Loading from "../component/Loading";
import CourseComments from "../component/CourseComments";
import CourseLinks from "../component/CourseLinks";
import CourseSubDetails from "../component/CourseSubDetails";
import CoursePayment from "../component/CoursePayment";
import ModalSubPrice from "../component/ModalSubPrice";
import RelatedCourses from "../component/RelatedCourses";
import FreeCourseLink from "../component/FreeCourseLink";

class CourseDetails extends Component {
  state = {
    data: {},
    courseId: "",
    courseLinks: [],
    comments: [],
    presenter: [],
    loading: true,
    enrollcoursing: [],
    checking: "",
  };

  scrollTopFun = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
  };

  componentDidMount() {
    document.title = "تفاصيل التدريب";
    this.scrollTopFun();
    const courseId = this.props.match.params.courseId;
    sessionStorage.setItem("coursseId", courseId);
    this.setState({
      courseId,
    });
    this.retreveDate(courseId);
    this.checkEnrolling();
  }

  checkEnrolling = async () => {
    const courseId = sessionStorage.getItem("coursseId");
    const studentId = sessionStorage.getItem("studentId");

    axios
      .get(`${enrollingCheacking_Url}/${courseId}/${studentId}`)
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            checking: res.data,
          });
        }
      });
  };

  componentDidUpdate(prevProps) {
    this.scrollTopFun();
    // Typical usage (don't forget to compare props):
    if (prevProps.match.params.courseId !== this.props.match.params.courseId) {
      this.componentDidMount();
    }
  }

  retreveDate = (courseId) => {
    axios
      .get(`${CourseDetails_Url}/${courseId}`)
      .then((res) => {
        if (res.status == 200) {
          const data = res.data;
          // console.log(data);

          this.setState({
            data,
            courseLinks: res.data.courseLinks,
            // comments: res.data.comments,
            presenter: res.data.presenter,
            loading: false,
          });
          this.filterCommsnt(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error.response);
        }
      });
  };

  filterCommsnt = (data) => {
    var newCommsnts = [];
    data.comments.map((item) => {
      if (item.isApproved) {
        newCommsnts.push(item);
      }
    });
    this.setState({
      comments: newCommsnts,
    });
  };

  render() {
    const {
      data,
      loading,
      courseId,
      courseLinks,
      comments,
      presenter,
      checking,
    } = this.state;
    // console.log()
    return (
      <div>
        <Loading loading={loading} />
        <Breadcrumb head="تفاصيل الكورس" />
        <div className="course-details">
          <div className="container">
            <div className="cours-det">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-5">
                  <div className="course-img">
                    <div className="img_parent">
                      <img src={`${Bublic_url}/${data.courseImage}`} alt="" />
                    </div>
                  </div>
                  <div className="intro">
                    <a
                      data-fancybox="gallery1"
                      href={data.descriptionVideoUrl}
                      className="btn btn-block"
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/images/webcam.webp`}
                        alt=""
                      />
                      <span>مشاهدة العرض</span>
                    </a>
                  </div>
                  {checking ? (
                    <h4 className="enroll_done">
                      انت بالفعل مشترك في هذا التدريب
                    </h4>
                  ) : (
                    <div className="intro buy-course">
                      {loading ? null : (
                        <div className="paymentOption">
                          <CoursePayment
                            coursePrice={data.coursePrice}
                            thcourseName={data.name}
                          />
                          <ModalSubPrice minimumprice={data.minimumprice} thcourseName={data.name} />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="latest-course">
                    <div className="latest-head">
                      <h5>تم اضافتها حديثا</h5>
                    </div>
                    <RelatedCourses />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-7">
                  <div className="course-info">
                    <h5 className="course-info-det">تفاصيل الكورس</h5>
                    <CourseSubDetails data={data} presenter={presenter} />
                  </div>
                  <div className="coursr-descreption-content">
                    <Tabs
                      defaultActiveKey="home"
                      transition={false}
                      id="noanim-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="home" title="الوصف">
                        <h5 className="description-title">وصف الكورس</h5>
                        <p className="description-desc" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                      </Tab>
                      <Tab eventKey="profile" title="المحتوى">
                        {data.courseLink != null ? (
                          <FreeCourseLink data={data} />
                        ) : null}

                        {/* course links */}
                        <CourseLinks
                          courseLinks={courseLinks}
                          courseId={courseId}
                        />
                      </Tab>
                    </Tabs>
                  </div>

                  {/* comments */}
                  {loading ? null : (
                    <CourseComments comments={comments} courseId={courseId} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CourseDetails;
