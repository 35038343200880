import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  aboutUs_url,
  Student_Url,
  updateQuestion_Url,
} from "../component/BaseUrl";
import Breadcrumb from "../component/Breadcrumb";
import CheckToken from "../component/CheckToken";
import Footer from "../component/Footer";
import Loading from "../component/Loading";

class Affiliatemarketing extends Component {
  state = {
    loading: true,
    studentId: "",
    createInterval: "",
    studentQes: "",
    qes: "",
  };

  componentDidMount() {
    document.title = "التسويق بالعموله";

    this.setState({
      loading: false,
    });

    const studentId = sessionStorage.getItem("studentId");
    this.setState({
      studentId,
    });

    this.retreveDataForStudent(studentId);
  }

  retreveDataForStudent = (studentId) => {
    axios
      .get(`${Student_Url}/${studentId}`)
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          // console.log(res.data.qes);
          this.setState({
            loading: false,
            studentQes: res.data.qes,
          });
        }
      })
      .catch((error) => {
        // console.log(error.response);
      });
  };

  createIntervalLink = async (e) => {
    e.preventDefault();
    // console.log(this.state.qes);
    if (this.state.qes == "") {
      toast.error(`برجاء الاجابه على السؤال`);
    } else {
      this.setState({
        loading: true,
      });
      const studentId = sessionStorage.getItem("studentId");
      // console.log(studentId);
      await axios
        .post(`${updateQuestion_Url}/${studentId}?qes=${this.state.qes}`)
        .then((res) => {
          if (res.status == 201) {
            this.setState({
              loading: false,
            });
            toast.success(`تم ارسال الاجابه بنجاح`);
          }
        })
        .catch((error) => {
          // console.log(error.response);
        });

      this.retreveDataForStudent(studentId);
    }
  };

  handeChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { loading, studentId, createInterval, qes, studentQes } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <Breadcrumb head="التسويق بالعموله" />
        <div className="affilate_marketing">
          <div className="container">
            <h5>التسويق بالعمولة</h5>
            <p>
              دلوقتي تقدر تنضم ل فريق الماركتنج الخاص بالاكاديمي من خلال نسخ
              الرابط ادناه وارساله للاصدقاء ونشره على مواقع السوشيال ميديا
              وعندما يقوم اى شخص بحجز اى تدريب ستحصل على عموله 10% من سعر
              التدريب ويمكنك استلام ارباحك كل يوم سبت من كل اسبوع
            </p>
            <h4>استخدم هذا الرابط لدعوة الاصدقاء والحصول على المكافآت</h4>

            {studentId ? (
              <>
                {studentQes != null ? (
                  <Link
                    to={`/?stud=${studentId}`}
                  >{`http://localhost:3000/?stud=${studentId}`}</Link>
                ) : (
                  <>
                    <h6>برجاء الاجابه على السؤال اولا </h6>
                    <form onSubmit={this.createIntervalLink}>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="qes"
                        value={qes}
                        onChange={this.handeChange}
                        placeholder="هل سبق ان عملت في مجال التسويق"
                      />
                      <button type="submit" className="btn btn-primary">
                        ارسل
                      </button>
                    </form>
                  </>
                )}
              </>
            ) : (
              <h6>يجب عليك التسجيل اولا</h6>
            )}
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Affiliatemarketing;
