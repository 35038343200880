import React, { Component } from 'react'
import { withRouter } from "react-router";
import { toast } from 'react-toastify';
import Loading from './Loading';
class LogOut extends Component {

  state = {
    loading: false,
  }

  logout = () =>{
    this.setState({
      loading: true,
    })
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("studentId");
    toast.success(`تم تسجيل الخروج بنجاح`)
    setTimeout(()=>{
      this.props.history.replace('/login');
      window.location.reload();
    }, 1000)
  }

  render() {
    const {loading} = this.state;
    return (
      <>
      <Loading loading={loading} />
        <div className="log-out">
          <button className="btn" onClick={this.logout}>
            <i className="fi-rr-sign-out"></i>
            <span>تسجيل الخروج</span>
          </button>
        </div>
      </>
    )
  }
}

export default withRouter(LogOut)