import React, { Component } from "react";

// component
import Breadcrumb from "../component/Breadcrumb";

// react bootstrap
import Tabs from "react-bootstrap/Tabs";
import TabContent from "react-bootstrap/TabContent";
import { Tab, Nav } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Footer from "../component/Footer";
import CheckToken from "../component/CheckToken";
import { toast } from "react-toastify";
import AccountInfo from "../component/AccountInfo";
import axios from "axios";
import { Student_Url } from "../component/BaseUrl";
import Loading from "../component/Loading";
import AccoundUpdate from "../component/AccoundUpdate";
import LogOut from "../component/LogOut";
import MyEnrollCourses from "../component/MyEnrollCourses";
import AccountAffiliatemarketing from "../component/AccountAffiliatemarketing";


class Profile extends Component {

  state = {
    fullName: "",
    address: "",
    email: "",
    phone: "",
    data: {},
    loading: true,
  }

  componentDidMount=()=>{
    document.title = 'الملف الشخصي';
    const studentId = sessionStorage.getItem("studentId");
    this.retreveData(studentId);
  }

  retreveData=(studentId)=>{
    axios.get(`${Student_Url}/${studentId}`).then((res)=>{
      // console.log(res);
      if(res.status == 200){
        // console.log(res.data)
        this.setState({
          loading: false,
          data: res.data
        })
      }
    }).catch((error)=>{
      // console.log(error.response)
    })
  }


  render() {

    const checkToken = CheckToken();
    const {loading, data} = this.state;
    if (!checkToken) {
      return <Redirect push to="/login" />;
    }
    return (
      <div>
        <Loading loading={loading} />
        <Breadcrumb head="معلومات الحساب" />
        <div className="my-account">
          <div className="container">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="accountInfo"
            >
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-3">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="accountInfo">
                        <i className="fi-rr-user"></i>
                        معلومات الحساب
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="myCources">
                        <i className="fi-rr-book-alt"></i>كورساتي
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="affilmarket">
                        <i className="fi-rr-settings"></i>مكافآت التسويق
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="settings">
                        <i className="fi-rr-settings"></i>الاعدادات
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <LogOut />
                </div>
                <div div className="col-sm-12 col-md-8 col-lg-9">
                  <Tab.Content>
                    <Tab.Pane eventKey="accountInfo">
                      <div className="tabHed">
                        <h5>معلومات الحساب</h5>
                      </div>
                      <div className="tabContent">
                      <AccountInfo data={data} />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="myCources">
                      <div className="tabHed">
                        <h5>كورساتي</h5>
                      </div>
                      <div className="tabContent">
                        <div className="row">
                          <MyEnrollCourses />
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="affilmarket">
                      <div className="tabHed">
                        <h5>مكافآت التسويق بالعمولة</h5>
                      </div>
                      <div className="tabContent">
                      <AccountAffiliatemarketing  data={data} />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="settings">
                      <div className="tabHed">
                        <h5>اعداداتي</h5>
                      </div>
                      <div className="tabContent">
                         <AccoundUpdate data={data} />           
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Profile;
