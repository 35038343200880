import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Loading from "./Loading";
import { studentRequest_Url } from "./BaseUrl";
import axios from "axios";
import { toast } from "react-toastify";

class WithdrowOrder extends Component {
  state = {
    amount: 0,
    studentId: "",
    loading: false,
  };

  componentDidMount = () => {
    const studentId = sessionStorage.getItem("studentId");
    this.setState({
      studentId: studentId,
    });
  };

  withdrowOrder = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    axios
      .post(
        `${studentRequest_Url}/${this.state.studentId}?amount=${this.state.amount}`
      )
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            loading: false,
          });
          toast.success(`تم ارسال الطلب بنجاح`);
        }
      });
  };

  handleAmountChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { amount, loading } = this.state;
    const { handleClose, show, requestId } = this.props;
    return (
      <>
        <Loading loading={loading} />
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <h5>برجاء كتابه المبلغ الذي سيتم سحبه</h5>
            <form
              action=""
              onSubmit={this.withdrowOrder}
              className="form_style withdrow_modal"
            >
              <div className="form-group">
                <input
                  type="text"
                  name="amount"
                  value={amount}
                  onChange={this.handleAmountChange}
                  id=""
                  className="form-control"
                  placeholder="المبلغ"
                />
              </div>
              <button className="btn btn-success" type="submit" onClick={handleClose}>ارسال</button>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default WithdrowOrder;
