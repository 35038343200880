
function HomeContcat() {
  return (

    <>
      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="section-contact">
                <h6>تواصل معنا</h6>
                <h5>هل لديك استفسار ؟</h5>
              </div>
              <div className="cont">
                <div className="cont-img">
                  <img src="./images/phone-call (2).png" alt="" />
                </div>
                <div className="con-content">
                  <h5>رقم الهاتف</h5>
                  <p><a href="tel:01007650643">01007650643</a></p>
                  <p><a href="tel:01033029112">01033029112</a></p>
                </div>
              </div>
              <div className="cont">
                <div className="cont-img">
                  <img src="./images/Page-1.png" alt="" />
                </div>
                <div className="con-content">
                  <h5>البريد الالكتروني</h5>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="contact-img">
                <img src="./images/contact.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeContcat