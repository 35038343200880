import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Get_Last5Courses_Url } from "./BaseUrl";
import CourseCard from "./CourseCard";

class RelatedCourses extends Component {
  state = {
    data: [],
  };

  componentDidMount = () => {
    this.retreveData();
  };

  retreveData = () => {
    axios.get(`${Get_Last5Courses_Url}`).then((res) => {
      if (res.status == 200) {
        // console.log(res.data);
        const data = res.data.reverse();
        this.setState({
          data: data,
        });
      }
    });
  };

  render() {
    const { data } = this.state;
    return (
      <div className="row">
        {data.map((item) => (
          <div className="col-sm-12 col-md-6 col-lg-6" key={item.id}>
            <Link to={`/course-details/${item.id}`}>
              <CourseCard item={item} />
            </Link>
          </div>
        ))}
      </div>
    );
  }
}

export default RelatedCourses;
