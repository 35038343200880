import { useEffect, useState } from "react";
import axios from "axios";
import { getData_Url } from "./BaseUrl";

function HomeOverView() {
  const [data, setData] = useState({});

  useEffect(() => {
    axios.get(`${getData_Url}/23`).then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });
  }, []);

  return (
    <>
      <div className="overview">
        <div className="container">
          <img className="overview-img" src="./images/logo.png" alt="" />
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div
                className="over-content-1"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h5>عن بيت العلم المصري</h5>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div
                className="over-content-2"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <p dangerouslySetInnerHTML={{ __html: data.value }}></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeOverView;
