import React, { Component } from "react";

export class CourseSubDetails extends Component {
  render() {
    const {data} = this.props;
    return (
      <>
        <ul className="list-unstyled">
          <li>
            <h5 className="title">
              <i className="fi-rr-book"></i>
              <span>اسم الكورس</span>
            </h5>
            <h6 className="desc"> {data.name} </h6>
          </li>
          <li>
            <h5 className="title">
              <i className="fi-rr-money"></i>
              <span>سعر الكورس</span>
            </h5>
            <h6 className="desc">{data.coursePrice} ج.م</h6>
          </li>
          <li>
            <h5 className="title">
              <i className="fi-rr-money"></i>
              <span>الحد الادنى</span>
            </h5>
            <h6 className="desc">{data.minimumprice} ج.م</h6>
          </li>
          <li>
            <h5 className="title">
              <i className="fi-rr-clock"></i>
              <span>مدة الكورس</span>
            </h5>
            <h6 className="desc">{data.time} ساعه</h6>
          </li>
          <li>
            <h5 className="title">
              <i className="fi-rr-network"></i>
              <span>جهة الكورس</span>
            </h5>
            <h6 className="desc">
              <img src={`${process.env.PUBLIC_URL}/images/building.jpg`} alt="" />
              <span> {data.presenter?.fullName } </span>
            </h6>
          </li>
        </ul>
      </>
    );
  }
}

export default CourseSubDetails;
