import React from "react";

function FreeCourseLink({data}) {
  return (
    <>
      <div className="course_video_free">
        <div className="card">
          <div target="_blank" className="card-title">
            <span>محاضره مجانيه</span>
            <a
              data-fancybox="gallery2"
              className="btn"
              href={data.courseLink}
              target="_blank"
            >
              <span>مشاهده</span>
              <i className="fi-rr-link"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default FreeCourseLink;
