import axios from "axios";
import React, { Component } from "react";
import parse from 'html-react-parser';
import { GetData_Url, Get_Statistics_Url } from "./BaseUrl";

class Statistics extends Component {

  state = {
    data: {},
    innerStudentCount: 0,
  }

  componentDidMount=()=>{
    this.retreveData();
  }


  retreveData=()=>{
    axios.get(`${Get_Statistics_Url}`).then((res)=>{
      if(res.status ==200){
        this.setState({
          data: res.data
        })
      }
    })

    axios.get(`${GetData_Url}/25`).then((res) => {
      if (res.status === 200) {
        // let innerstudentCount = parseInt(res.data.value);
        // let spa = dangerouslySetInnerHTML={{ __html: res.data.value}}
        // console.log(spa)
        let parseinnerStudentCount = parse(res.data.value);
        let innerStudentCount = parseInt(parseinnerStudentCount.props.children);
        this.setState({
          innerStudentCount
        })
      }
    });
  }

  render() {
    const {data, innerStudentCount} = this.state
    // innerstudentCount = parseInt(innerstudentCount)
    return (
      <div className="statics">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3 mx-auto">
              <div className="state-card">
                <div className="card-img card-img-1">
                  <img src={`${process.env.PUBLIC_URL}/images/student.svg`} alt="" />
                </div>
                <span className="card-number" dangerouslySetInnerHTML={{ __html: data.studentCount + innerStudentCount }}></span>
                <h5 className="card-title">طالب</h5>
                {/* <p className="card-text">
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                </p> */}
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="state-card">
                <div className="card-img card-img-2">
                  <img src={`${process.env.PUBLIC_URL}/images/teacher.svg`} alt="" />
                </div>
                <span className="card-number"> {data.partnerCount} </span>
                <h5 className="card-title">مدرسين</h5>
                <p className="card-text">
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="state-card">
                <div className="card-img card-img-3">
                  <img src={`${process.env.PUBLIC_URL}/images/course.svg`} alt="" />
                </div>
                <span className="card-number"> {data.coursesCount} </span>
                <h5 className="card-title">كورسات</h5>
                <p className="card-text">
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="state-card">
                <div className="card-img card-img-4">
                  <img src={`${process.env.PUBLIC_URL}/images/certificate-svgrepo-com.svg`} alt="" />
                </div>
                <span className="card-number"> {data.certificateCount} </span>
                <h5 className="card-title">شهادات</h5>
                <p className="card-text">
                  هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Statistics;
