export const Bublic_url = "https://americanboard-us.com";
export const Base_Url = "https://americanboard-us.com/api";
export const Course_Url = "https://americanboard-us.com/api/Courses/GetCourses";
export const CourseDetails_Url = "https://americanboard-us.com/api/Courses";
export const Student_Url = "https://americanboard-us.com/api/Students";
export const Course_Comments_Url  ="https://americanboard-us.com/api/CourseComments";
export const Authentication_Request_Url = " https://accept.paymob.com/api/auth/tokens";
export const Order_Registeration_Url = "https://accept.paymob.com/api/ecommerce/orders";
export const Payment_Key_Request_Url = "https://accept.paymob.com/api/acceptance/payment_keys";
export const Get10_Comment_Url = "https://americanboard-us.com/api/Meta/Get10Commet";
export const Get_Statistics_Url = "https://americanboard-us.com/api/Meta/GetStatistics";
export const Get_Last5Courses_Url = "https://americanboard-us.com/api/Courses/GetLast5Courses";
export const Services_Url = "https://americanboard-us.com/api/OtherServices";
export const getDataBySerialNumber = "https://americanboard-us.com/api/Certificate/result";
export const StudenEnrollCourses_Url = "https://americanboard-us.com/api/Students/GetCourses";
export const getData_Url = "https://americanboard-us.com/api/Meta/getData";
export const intervalId_info ="https://americanboard-us.com/api/Payments/GetCashBackDetails";
export const enrollingCheacking_Url = "https://americanboard-us.com/api/Students/isEnrolled";
export const studentRequest_Url = "https://americanboard-us.com/api/Students/AddStudentRequests";
export const updateQuestion_Url = "https://americanboard-us.com/api/Students/UpdateQes";
export const contactForm_Url = "https://americanboard-us.com/api/ContactUs";
export const imageUpload_Url = "https://americanboard-us.com/api/Meta/UploadImage";
export const Bublic_Url = "https://americanboard-us.com";
export const GetData_Url = "https://americanboard-us.com/api/Meta/getData";
