import axios from "axios";
import React, { Component } from "react";
import { aboutUs_url, getData_Url } from "../component/BaseUrl";
import Breadcrumb from "../component/Breadcrumb";
import Footer from "../component/Footer";
import Loading from "../component/Loading";

class AboutUs extends Component {
  state = {
    data: {},
    loading: false,
  };

  componentDidMount = () => {
    this.retreveData();
    document.title = 'من نحن';

  };

  retreveData = () => {
    this.setState({
      loading: true,
    });
    axios.get(`${getData_Url}/24`).then((res) => {
      if (res.status == 200) {
        this.setState({
          data: res.data,
          loading: false,
        });
      }
    });
  };

  render() {
    const { data, loading } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <Breadcrumb head="من نحن" />
        <div className="about_us_page">
          <div className="container">
            <h5>{data.name}</h5>
            <p dangerouslySetInnerHTML={{ __html: data.value }}></p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default AboutUs;
