import axios from "axios";
import React, { Component } from "react";
import { toast } from "react-toastify";

import SimpleReactValidator from "simple-react-validator";
import { Bublic_Url, imageUpload_Url, Student_Url } from "./BaseUrl";
import Loading from "./Loading";

class AccoundUpdate extends Component {
  state = {
    fullName: "",
    address: "",
    email: "",
    phone: "",
    id: "",
    loading: false,
    picturepreview: "",
    imagePath: "",
    password: "",
  };

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
  }

  componentDidMount = () => {
    const data = this.props.data;
    const studentId = sessionStorage.getItem("studentId");
    this.setState({
      fullName: data.fullName,
      address: data.address,
      email: data.email,
      phone: data.phone,
      id: studentId,
      imagePath: data.imagePath,
      picturepreview: data.imagePath,
      password: data.password,
    });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const data = this.props.data;
    const studentId = sessionStorage.getItem("studentId");
    if (this.props.data !== prevProps.data) {
      // this.fetchData(this.props.userID);
      this.setState({
        fullName: data.fullName,
        address: data.address,
        email: data.email,
        phone: data.phone,
        id: studentId,
        imagePath: data.imagePath,
        picturepreview: data.imagePath,
        password: data.password,
      });
    }
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handelUploadPicture = (e) => {
    this.setState({
      loading: true,
    });
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios.post(`${imageUpload_Url}`, formData).then((res) => {
      // console.log(res);
      if (res.status == 200) {
        this.setState({
          picturepreview: res.data.dbPath,
          imagePath: res.data.dbPath,
          loading: false,
        });
      }
    });
  };

  submitForm = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    const state = this.state;
    delete state.loading;
    delete state.picturepreview;
    // console.log(state);
    if (this.validator.allValid()) {
      axios
        .put(`${Student_Url}/${state.id}`, state)
        .then((res) => {
          // console.log(res);
          if (res.status == 204) {
            toast.success(`تم التعديل بنجاح`);
            this.setState({
              loading: false,
            });
            window.location.reload();
            // this.componentDidMount();
            // this.props.history.replace('/profile');
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
          }
          this.setState({
            loading: false,
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      toast.error("برجاء مراجعة الأخطاء");
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { fullName, address, email, phone, loading, picturepreview, password } = this.state;

    const { data } = this.props;
    return (
      <>
        {/* <Loading loading={loading} /> */}
        <form action="" onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="form-group">
                <div className="course_img">
                  <label htmlFor="courseImg">
                    <div className="card_img">
                      {picturepreview == "" ? (
                        <div className="img_parent">
                          <img
                            src={`${process.env.PUBLIC_URL}/images/vatar.webp`}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="img_parent">
                          <img src={`${Bublic_Url}/${picturepreview}`} alt="" />
                        </div>
                      )}
                    </div>
                  </label>
                  <input
                    type="file"
                    id="courseImg"
                    onChange={this.handelUploadPicture}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <label htmlFor="nameS">الاسم</label>
                <input
                  type="text"
                  name="fullName"
                  id="nameS"
                  className="form-control"
                  placeholder="اسم الطالب"
                  value={fullName}
                  onChange={this.handelChange}
                />
                <span className="error_handle">
                  {this.validator.message(
                    "fullName",
                    this.state.fullName,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <label htmlFor="emailS">البريد الالكتروني</label>
                <input
                  type="email"
                  name="email"
                  id="emailS"
                  className="form-control"
                  placeholder="البريد الالكتروني"
                  value={email}
                  onChange={this.handelChange}
                />
                <span className="error_handle">
                  {this.validator.message(
                    "email",
                    this.state.email,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <label htmlFor="phoneS">رقم الهاتف</label>
                <input
                  type="number"
                  name="phone"
                  id="phoneS"
                  className="form-control"
                  placeholder="01098586783"
                  value={phone}
                  onChange={this.handelChange}
                />
                <span className="error_handle">
                  {this.validator.message(
                    "phone",
                    this.state.phone,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <label htmlFor="adressS">العنوان</label>
                <input
                  type="text"
                  name="address"
                  id="adressS"
                  className="form-control"
                  placeholder="01098586783"
                  value={address}
                  onChange={this.handelChange}
                />
                <span className="error_handle">
                  {this.validator.message(
                    "address",
                    this.state.address,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="form-group">
                <label htmlFor="passS">الرقم السري</label>
                <input
                  type="password"
                  name="password"
                  id="passS"
                  className="form-control"
                  placeholder="الرقم السري"
                  onChange={this.handelChange}
                  value={password}
                />
                <span className="error_handle">
                  {this.validator.message(
                    "password",
                    this.state.password,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-12 col-lg-12">
              <div className="btn-submit">
                <button type="submit" className="btn">
                  حفظ التعديلات
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default AccoundUpdate;
