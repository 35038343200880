import axios from 'axios';
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import SimpleReactValidator from "simple-react-validator";
import { Course_Comments_Url } from './BaseUrl';
import Loading from './Loading';

class AddCommentForm extends Component {


  state = {
    comment: "",
    studentId: "",
    courseId: "",
    loading: false,
  }

  componentDidMount(){
    const {courseId} = this.props;
    const studentId = sessionStorage.getItem('studentId');
    this.setState({
      studentId,
      courseId
    })
  }

    componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.courseId !== prevProps.courseId) {
      // this.fetchData(this.props.userID);
      const {courseId} = this.props;
      this.setState({
        courseId
      })
    }
  }

  constructor() {
    super();
    this.validator = new SimpleReactValidator();
  }

  handelChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };



  submitForm = (e) => {
    this.setState({
      loading: true,
    });
    e.preventDefault();
    const state = this.state;
    delete state.loading;
    // console.log(state);
    if (this.validator.allValid()) {
      axios
        .post(`${Course_Comments_Url}/AddComment`, state)
        .then((res) => {
          if (res.status == 201) {
            toast.success(`تم ارسال التعليق`);
            this.setState({
              loading: false,
              comment: "",
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response) {
            // console.log(error.response);
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      toast.error("برجاء مراجعة الأخطاء");
      this.setState({
        loading: false,
      });
    }
  };



  render() {
    const { comment, loading } = this.state;
    return (
      <>
      <Loading loading={loading} />
        <form action="" onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <div className="form-group">
                <textarea
                  name="comment"
                  id=""
                  className="form-control"
                  placeholder="تعليقك"
                  value={comment}
                  onChange={this.handelChange}
                ></textarea>
                <span className="error_handle">
                  {this.validator.message(
                    "comment",
                    this.state.comment,
                    "required"
                  )}
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12">
              <div className="btn-submit">
                <button type="submit" className="btn">
                  اضف تعليق
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default AddCommentForm