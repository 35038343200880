import axios from "axios";
import { useState, useEffect, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import { contactForm_Url } from "./BaseUrl";
import { toast } from "react-toastify";
import Loading from './Loading';

// validator = new SimpleReactValidator();

function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [, forceUpdate] = useState();
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
    type: 1,
  });

  const simpleValidator = useRef(new SimpleReactValidator());

  const handelChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };

  const submitForm = (e) => {
    setLoading(true)
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      axios.post(`${contactForm_Url}`, input).then((res) => {
        if (res.status === 201) {
          toast.success(`تم الارسال بنجاح`);
          setLoading(false);
          setInput({
            name: "",
            email: "",
            phone: "",
            description: "",
            type: 1,
          })
        }
      });
    } else {
      simpleValidator.current.showMessages();
      toast.error(`برجاء التاكد من البيانات`);
      forceUpdate(1);
      setLoading(false);
    }
  };

  return (
    <>
      <Loading loading={loading} />
      <div className="container">
        <form onSubmit={submitForm} className="contact_form">
          <div className="row">
            <div className="col-sm-12 col-sm-12 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={input.name}
                  onChange={handelChange}
                  id=""
                  className="form-control"
                  placeholder="الاسم"
                  onBlur={() => simpleValidator.current.showMessageFor("name")}
                />
                {simpleValidator.current.message(
                  "name",
                  input.name,
                  "required"
                )}
              </div>
            </div>
            <div className="col-sm-12 col-sm-12 col-lg-6">
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  value={input.phone}
                  onChange={handelChange}
                  id=""
                  className="form-control"
                  placeholder="رقم الهاتف"
                  onBlur={() => simpleValidator.current.showMessageFor("phone")}
                />
                {simpleValidator.current.message(
                  "phone",
                  input.phone,
                  "required"
                )}
              </div>
            </div>
            <div className="col-sm-12 col-sm-12 col-lg-12">
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  value={input.email}
                  onChange={handelChange}
                  id=""
                  className="form-control"
                  placeholder="البريد الالكتروني"
                  onBlur={() => simpleValidator.current.showMessageFor("email")}

                />
                 {simpleValidator.current.message(
                  "email",
                  input.email,
                  "required"
                )}
              </div>
            </div>
            <div className="col-sm-12 col-sm-12 col-lg-12">
              <div className="form-group">
                <textarea
                  name="description"
                  value={input.description}
                  onChange={handelChange}
                  className="form-control"
                  id=""
                  placeholder="ملاحظات"
                ></textarea>
              </div>
            </div>
            <div className="col-sm-12 col-lg-12 text-center">
              <div className="btn_submit">
                <button type="submit" className="btn">
                  ارسال
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactForm;
