import React, { Component } from "react";

class WhyBetelelm extends Component {
  render() {
    return (
      <div className="container">
        <div className="main-video-parent">
          <div className="section-header">
            <div className="sec-head">
              <h5>لماذ بيت العلم المصري</h5>
              <p>نبذة صغيرة عنا</p>
            </div>
          </div>
          <div
            className="main-video"
            style={{ backgroundImage: `url('./images/about.png')` }}
          >
            <button className="btn-play">
              <i className="fas fa-play"></i>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyBetelelm;
