import React, { Component } from "react";
import axios from "axios";
import { StudenEnrollCourses_Url } from "./BaseUrl";
import CourseCard from "./CourseCard";
import { Link } from "react-router-dom";

class MyEnrollCourses extends Component {
  state = {
    enrollcourses: [],
  };

  componentDidMount() {
    this.retreveData();
  }

  retreveData = () => {
    const studentId = sessionStorage.getItem("studentId");
    axios
      .get(`${StudenEnrollCourses_Url}/StudentId?StudentId=${studentId}`)
      .then((res) => {
        if (res.status == 200) {
          this.setState({
            enrollcourses: res.data,
          });
        }
      });
  };

  render() {
    const { enrollcourses } = this.state;
    return (
      <>
        {enrollcourses.map((course) => (
          <div className="col-md-12 col-lg-6" key={course.id}>
            <Link to={`/course-details/${course.id}`}>
              <CourseCard item={course} />
            </Link>
          </div>
        ))}
      </>
    );
  }
}

export default MyEnrollCourses;
