import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import CoursePayment from "./CoursePayment";

export class ModalSubPrice extends Component {
  state = {
    show: false,
    coursePrice: "",
    thcourseName: "",
  };


  componentDidMount=()=>{
    var thcourseName = this.props.thcourseName;
    var minimumprice = this.props.minimumprice;
    this.setState({
      thcourseName,
      coursePrice: minimumprice
    })
  }


  componentDidUpdate(prevProps) {
    // this.scrollTopFun();
    // Typical usage (don't forget to compare props):
    if (prevProps.minimumprice !== this.props.minimumprice) {
      this.componentDidMount();
    }
  }

  // handelChange=(e)=>{
  //   this.setState({
  //     [e.target.name] : e.target.value
  //   })
  // }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    const { show, coursePrice, thcourseName } = this.state;
    // const {minimumprice} = this.props;
    return (
      <>
        <button className="btn btn-block subPrice" onClick={this.handleShow}>
          <img src={`${process.env.PUBLIC_URL}/images/tag.webp`} alt="" />
          <span>دفع الحد الادنى</span>
        </button>
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
          onHide={this.handleClose}
          centered
          className="modalSubPrice"
        >
          <Modal.Header closeButton>
            <Modal.Title>دفع الحد الادنى من ثمن التدريب</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div action="" className="subPriceForm">
              <input type="number" name="coursePrice" disabled value={coursePrice} id="" className="form-control" />
              <CoursePayment coursePrice={coursePrice} thcourseName={thcourseName} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              variant="secondary"
              onClick={this.handleClose}
            >
              اغلاق
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ModalSubPrice;
