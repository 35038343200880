import React, { Component } from "react";

// component
import Footer from "../component/Footer";
import Header from "../component/Header";
import HomeCourses from "../component/HomeCourses";
import Services from "../component/Services";
import Statistics from "../component/Statistics";
import Testimonial from "../component/Testimonial";
import WhyBetelelm from "../component/WhyBetelelm";
import HomeOverView from '../component/HomeOverView';
import HomeContcat from "../component/HomeContcat";
import ContactForm from "../component/ContactForm";



class Home extends Component {

  componentDidMount(){
    this.getIntervalId();
    document.title = 'الرئيسية';
  }

  getIntervalId = () =>{
    const inviterId = this.props.location.search;
    var obj = new URLSearchParams(inviterId);
    const params = Object.fromEntries(new URLSearchParams(obj));
    sessionStorage.setItem("inviterId", params.stud);
    // console.log(params.stud);
  }

  render() {
    
    return (
      <div>
        {/* header */}
        <Header />


        {/* overview */}
        <HomeOverView />


        {/* statistcs */}
        <Statistics />

        {/* Courses */}
        <HomeCourses />

        {/* testominal */}
        <Testimonial />


        {/* why */}
        {/* <WhyBetelelm /> */}


        {/* our services */}
        {/* <Services /> */}

        {/* <!-- contact --> */}
        <HomeContcat />

        {/* Contact */}
        <ContactForm />


        {/* footer */}
        <Footer />
      </div>
    );
  }
}

export default Home;
