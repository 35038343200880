import React, { Component } from "react";
import Breadcrumb from "../component/Breadcrumb";
import ContactForm from "../component/ContactForm";
import Footer from "../component/Footer";
import HomeContcat from "../component/HomeContcat";
import Loading from "../component/Loading";

export class ContactUs extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    document.title = 'تواصل معنا';
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <Breadcrumb head="تواصل معنا" />

        {/* <!-- contact --> */}
        <HomeContcat />

        {/* Contact */}
        <ContactForm />

        <Footer />
      </>
    );
  }
}

export default ContactUs;
