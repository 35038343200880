import React, { Component } from "react";

class Loading extends Component {

  render() {
    const {loading, formpage} = this.props;
    return (
      <div className={`section-loading ${loading ? null : 'loading_none'} ${formpage ? "form_page" : null}` }>
        <ul className="list-bars">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    );
  }
}

export default Loading;
