import React, { Component } from "react";
import { Link } from "react-router-dom";
import CheckToken from "./CheckToken";
import DropLoginButton from "./DropLoginButton";

export default class Navbar extends Component {
  render() {
    const checkToken = CheckToken();
    const { openMobSideMenu } = this.props;
    return (
      <div>
        <div className="mynavbr">
          <div className="container">
            <div className="mynavbar-parent">
              <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
              </div>
              <ul className="links">
                <li className="navItem">
                  <Link to="/" className="navLink">
                    <i className="fi-rr-home"></i>
                    <span>الرئيسية</span>
                  </Link>
                </li>
                <li className="navItem">
                  <Link to="/courses" className="navLink">
                    <i className="fi-rr-book"></i>
                    <span>التدريبات</span>
                  </Link>
                </li>
          
                <li className="navItem">
                  <Link to="/affiliatemarketing" className="navLink">
                    <i className="fi-rr-users"></i>
                    <span>التسويق بالعمولة</span>
                  </Link>
                </li>
               
                <li className="navItem">
                  <Link to="/certificate" className="navLink">
                    <i className="fi-rr-headset"></i>
                    <span>الشهادات</span>
                  </Link>
                </li>
                <li className="navItem">
                  <Link to="/aboutus" className="navLink">
                    <i className="fi-rr-users"></i>
                    <span> من نحن</span>
                  </Link>
                </li>
                <li className="navItem">
                  <Link to="/contactus" className="navLink">
                    <i className="fi-rr-headset"></i>
                    <span>تواصل معنا</span>
                  </Link>
                </li>
              </ul>
              <div className="login">
                <Link to="/sign-up" className="navLink d-none d-md-block">
                  <i className="fi-rr-user-add"></i>
                  <span>حساب جديد</span>
                </Link>

                {checkToken ? (<DropLoginButton />) : (<Link to="/login" className="navLink">
                  <i className="fi-rr-key"></i>
                  تسجيل الدخول
                </Link>)}

              </div>
              <div className="sidemenue-icon" onClick={openMobSideMenu}>
                <i className="fas fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
