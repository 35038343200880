import React, { Component } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// css
import "./assets/css/style.css";

// compnent
import Navbar from "./component/Navbar";
import Sidebar from "./component/Sidebar";

//
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Profile from "./pages/Profile";
import CourseDetails from "./pages/CourseDetails";
import Courses from "./pages/Courses";
import Certificate from "./pages/Certificate";
import CertificateDetails from "./pages/CertificateDetails";
import PaymobRedirect from "./pages/PaymobRedirect";
import AboutUs from "./pages/AboutUs";
import Affiliatemarketing from "./pages/Affiliatemarketing";
import ContactUs from "./pages/ContactUs";

class App extends Component {
  render() {
    const openMobSideMenu = () => {
      var sidebar = (document.querySelector(".Mysidebar").style.right = 0);
      document.querySelector(".bg-sidenavOpen").style.display = "block";
      document.body.classList.add("openMenuActive");
    };

    const closeSideMenue = () => {
      var sidebar = (document.querySelector(".Mysidebar").style.right =
        "-300px");
      document.querySelector(".bg-sidenavOpen").style.display = "none";
      document.body.classList.remove("openMenuActive");
    };

    const removeSide = (e) => {
      document.querySelector(".Mysidebar").style.right = "-300px";
      e.target.style.display = "none";
      document.body.classList.remove("openMenuActive");
    };
    return (
      <>
        <ToastContainer position="top-right" autoClose={5000} rtl={true} draggable pauseOnHover closeOnClick newestOnTop={true} />
        <Router>
          <div className="bg-sidenavOpen" onClick={removeSide}></div>
          <div>
            <Navbar openMobSideMenu={openMobSideMenu} />
            <Sidebar closeSideMenue={closeSideMenue} />
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/sign-up" component={SignUp} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/courses" component={Courses} />
              <Route exact path="/certificate" component={Certificate} />
              <Route exact path="/certificate-details" component={CertificateDetails} />
              <Route exact path="/paymenredirect" component={PaymobRedirect} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/affiliatemarketing" component={Affiliatemarketing} />
              <Route
                exact
                path="/course-details/:courseId"
                component={CourseDetails}
              />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/" component={Home} />
              {/* <Route exact path="/refer" component={Home} /> */}
            </Switch>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
