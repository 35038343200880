import axios from "axios";
import React, { Component } from "react";
import AddComments from "./AddComments";
import { Bublic_Url, Course_Comments_Url } from "./BaseUrl";
import Loading from "./Loading";

class CourseComments extends Component {
  state = {
    data: [],
  };

  // componentDidMount = () => {

  // };

  render() {
    const { courseId, comments } = this.props;
    const { data } = this.state;
    // console.log(comments);
    return (
      <>
        {/* <Loading loading={loading} /> */}
        <div className="comments">
          <div className="comment-head">
            <span></span>
            <h5>التعليقات</h5>
            <span></span>
          </div>
          <div className="row">
            {comments.length == 0 ? null : (
              <>
                {comments.map((item) => (
                  <div className="col-sm-12 col-lg-12" key={item.id}>
                    <div className="comment-card">
                      <div className="name_img">
                        <div className="card_img">
                          <div className="img_parent">
                            {item.student.imagePath === null ? (
                              <img
                                src={`${process.env.PUBLIC_URL}/images/vatar.webp`}
                                alt=""
                              />
                            ) : (
                              <img
                                src={`${Bublic_Url}/${item.student.imagePath}`}
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                        <h5 className="card-title">{item.student.fullName}</h5>
                      </div>
                      <span className="card-date">
                        {" "}
                        {item.commentTime.split("T")[0]}{" "}
                      </span>
                      <p className="card-text">{item.comment}</p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <AddComments courseId={courseId} />
        </div>
      </>
    );
  }
}

export default CourseComments;
