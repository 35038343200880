import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className={this.props.pagetype == "footer" ? "nomarginh": ""} style={{backgroundImage: `url('${process.env.PUBLIC_URL}/images/footer.png')`}}>
          <img src="./images/logo.png" alt="" />
          <ul className="list-unstyled">
            <li>
              <a href=" https://www.instagram.com/ekartonah/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/kartonah">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href=" https://twitter.com/kartonah1">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/ekartonah/">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
          </ul>
        </footer>
      </div>
    );
  }
}

export default Footer;