import axios from "axios";
import React, { Component } from "react";

// react slick
import Slider from "react-slick";
import { Bublic_Url, Get10_Comment_Url } from "./BaseUrl";

class Testimonial extends Component {
  state = {
    data: [],
  };

  componentDidMount = () => {
    this.retreveData();
  };

  retreveData = () => {
    const newarr = [];
    axios.get(`${Get10_Comment_Url}`).then((res) => {
      if (res.status == 200) {
        res.data.map((item) => {
          if (item.isApproved) {
            newarr.push(item);
          }
        });
        this.setState({
          data: newarr,
        });
        // console.log(newarr);
      }
    });
  };

  render() {
    var settings = {
      dots: true,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 524,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const { data } = this.state;
    return (
      <div
        className="testemonial"
        style={{ backgroundImage: `url('./images/doooot.png')` }}
      >
        <div className="teste-bf">
          <img src="./images/wave2.svg" alt="" />
        </div>
        <div className="container">
          <div className="section-header">
            <div className="sec-head">
              <h5>اراء العملاء</h5>
              <p>ماذا قالو عنا</p>
            </div>
          </div>
          <Slider {...settings}>
            {data.map((item) => (
              <div className="item" key={item.id}>
                <div className="teste-card">
                  <div className="card-img">
                    <div className="img_parent">
                      {item.student.imagePath === null ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/images/vatar.webp`}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${Bublic_Url}/${item.student.imagePath}`}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <h5 className="card-name"> {item.student.fullName} </h5>
                  <span className="card-date">
                    {" "}
                    {item.commentTime.split("T")[0]}{" "}
                  </span>
                  <p className="card-text">{item.comment}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default Testimonial;
