import React, { Component } from 'react'
import AddCommentForm from './AddCommentForm';
import CheckToken from './CheckToken'


class AddComments extends Component {
  render() {
    const checkToken = CheckToken();
    const {courseId} = this.props;
    // console.log(courseId)
    return (
      <>
        <div className="add-comment">
          <div className="comment-head">
            <span></span>
            <h5>اضف تعليق</h5>
            <span></span>
          </div>
          {checkToken ? (<AddCommentForm courseId={courseId} />) : (<> <h4 className="noLogin_title">يجب عليك تسجيل الدخول اولا</h4> </>)}
          
        </div>
      </>
    )
  }
}

export default AddComments