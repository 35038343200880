import React from "react";

function Header() {
  return (
    <>
      <div className="header">
        <div className="bg-after" style={{ backgroundImage: `url('./images/wave4.svg')` }}></div>
        <div className="header_img_video">
            <video src={`${process.env.PUBLIC_URL}/videos/videon.mp4`} autoPlay playsInline muted loop></video>
        </div>
        <div className="container">
          {/* <div className="header-content">
            <h5>بيت العلم المصري</h5>
            <p>هذا النص هو يتم تغير في المكان الناسب</p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Header;
