import React, { Component } from "react";
import axios from "axios";
import { Services_Url } from "./BaseUrl";

class Services extends Component {
  state = {
    data: [],
  };

  componentDidMount = () => {
    this.retreveData();
  };

  retreveData = () => {
    var newdata = [];
    axios.get(`${Services_Url}`).then((res) => {
      if (res.status == 200) {
        newdata = res.data.reverse();
        this.setState({
          data: newdata,
        });
      }
    });
  };

  render() {
    const { data } = this.state;
    return (
      <div className="our-services">
        <div className="container">
          <div className="section-header">
            <div className="sec-head">
              <h5>خدماتنا</h5>
              <p>نبذة عن خدماتنا</p>
            </div>
          </div>
          <div className="row">
            {data.map((item, index) => (
              <>
                <div className="col-sm-12 col-md-6 col-lg-4"  key={item.id} >
                  <div className="serv-card">
                    {index < 9 ? (
                      <span
                        className="serv-number"
                        style={{
                          backgroundColor:
                            index % 2 == 1 ? "#fa6005" : "#478ecc",
                        }}
                      >
                        0{index + 1}
                      </span>
                    ) : (
                      <span
                        className="serv-number"
                        style={{
                          backgroundColor:
                            index % 2 == 1 ? "#fa6005" : "#478ecc",
                        }}
                      >
                        {index + 1}
                      </span>
                    )}

                    <h5 className="card-title"> {item.name} </h5>
                    <p className="card-text">{item.description}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
